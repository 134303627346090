import React, {useState, useEffect, useCallback} from 'react';
import './dashboard.scss';
import {Lookup} from "devextreme-react/lookup";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import PieChart, {
    Series,
    Label,
    Export,
} from 'devextreme-react/pie-chart';

import DataGrid, {
    Column, DataGridTypes,
    FilterRow, GroupItem, HeaderFilter, LoadPanel, Paging, Scrolling, SearchPanel
} from 'devextreme-react/data-grid';

import Chart, {Animation, Legend} from 'devextreme-react/chart';

import ruMessages from 'devextreme/localization/messages/ru.json';
import {loadMessages, locale} from "devextreme/localization";
import {Workbook} from "exceljs";
import {exportDataGrid} from "devextreme/excel_exporter";
import {saveAs} from "file-saver-es";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru"; // the locale you want
registerLocale("ru", ru);

export default function Dashboards () {
    //Руссификация сообщений
    locale(sessionStorage.getItem('locale') || 'ru');
    loadMessages(ruMessages);

    function generateMonthData(startYear, endYear) {
        const startDate = new Date(startYear, 0, 1); // Январь
        const endDate = new Date(endYear, 0, 1); // Январь
        const monthNames = ['ЯНВ', 'ФЕВ', 'МАР', 'АПР', 'МАЙ', 'ИЮН', 'ИЮЛ', 'АВГ', 'СЕН', 'ОКТ', 'НОЯ', 'ДЕК'];

        const result = [];
        while (startDate <= endDate) {
            const month = monthNames[startDate.getMonth()];
            let monthchis = startDate.getMonth()+1;
            if (monthchis < 10) {
                monthchis = "0" + monthchis;
            }
            const year = startDate.getFullYear();
            result.push({
                value: "0" +`${startDate.getDate()}.${monthchis}.${year}`,
                label: `${month} ${year}`
            });
            startDate.setMonth(startDate.getMonth() + 1);
        }
        return result;
    }
//Генерируем список для дат
    const goddata = generateMonthData(2022, new Date().getFullYear()+2);


    function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
    }

// Получаем первый день текущего месяца
    const date = new Date();
    const FirstDay = getFirstDayOfMonth(date.getFullYear(), date.getMonth());

    const shortDate = new Intl.DateTimeFormat("ru", {dateStyle: "short"}).format(FirstDay);
    const shortDates = shortDate.toString();








    const [selectregion, setselectregion] = useState();
    const [selectrayon, setselectrayon] = useState();
    const [selectdate, setselectdate] = useState(shortDates);
    const [dataobsh, setdataobsh]= useState([{'Всего':0,'СМеню':0,'БезМеню':0}]);
    const [loadobsh, setloadobsh]= useState(false);
    const [datagrafik, setdatagrafik]= useState();
    const [svodoshibok, setsvodoshibok]= useState();
    const [liders, setliders]= useState();
    const [outsider, setoutsider]= useState();

    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const handleMonthChange = (date) => {
        setSelectedMonth(date);
        setselectdate(date);
    };







    const selectedregion = useCallback(({ value }) => {

        setselectregion(value);
        setselectrayon();
    }, []);

    const selectedrayon = useCallback(({ value }) => {
        setselectrayon(value);
    }, []);

    const selecteddata = useCallback(({ value }) => {
        setselectdate(value);
    }, []);

    function Kruglash(value) {
        if(loadobsh){return(<div>
            <center><img src={require('./load.gif')}/><br />Загрузка...
                </center>
        </div>
        )
        }
        if (isNaN(value)) {
            return(<div></div>)};

const htmlString = value+', 100';
    return(
        <div>
            <ul>
                <li>Всего пищеблоков: {dataobsh.Всего}</li>
                <li>Пищеблоков, размещающих меню: {dataobsh.СМеню}</li>
                <li><b>Пищеблоков без меню: {dataobsh.БезМеню}</b></li>
            </ul>


            <svg viewBox="0 0 36 36" className="circular-chart">
                <path className="circle-bg"
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
                <path className="circle" stroke-dasharray={htmlString}
                      d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"/>
                <text x="18" y="20.35" className="percentage">{value}%</text>
            </svg>
        </div>
    )
    }

//Ослеживание события изменения региона,района, даты. Загрузка данных
    useEffect(() => {
        setdataobsh('[{}]');
        setdatagrafik();
        setsvodoshibok();
        setliders();
        if (!!selectregion && !!selectrayon && !!selectdate) {

            setloadobsh(true);
            //alert ('Ура!');
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'region': selectregion, 'rayon': selectrayon, 'date': selectdate})
            };
            fetch('https://api.cemon.ru/dashboard/', requestOptions)
                .then(response => response.json())
                .then((responseJson) => {setdataobsh(responseJson[0]);setloadobsh(false);})


            fetch('https://api.cemon.ru/dashboard/grafik/', requestOptions)
                .then(response => response.json())
                .then((responseJson) => {setdatagrafik(responseJson);})

            fetch('https://api.cemon.ru/dashboard/svodoshibok/', requestOptions)
                .then(response => response.json())
                .then((responseJson) => {setsvodoshibok(responseJson);})
            fetch('https://api.cemon.ru/dashboard/liders/', requestOptions)
                .then(response => response.json())
                .then((responseJson) => {setliders(responseJson);})
            fetch('https://api.cemon.ru/dashboard/outsiders/', requestOptions)
                .then(response => response.json())
                .then((responseJson) => {setoutsider(responseJson);})


    }}, [selectregion,selectrayon,selectdate])


    const onExporting = (e: DataGridTypes.ExportingEvent) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Выгрузка');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Экспорт.xlsx');
            });
        });
    };





    return (
        <div>
            <BrowserView>
            <details open>
                    <summary>Фильтр</summary>
                    <div className={'card'}>


                        <div className={'card-block'}>
                            <Lookup

                                labelMode='static'
                                stylingMode='outlined'
                                dataSource={'https://api.cemon.ru/listregion'}
                                displayExpr="label"
                                valueExpr="value"
                                searchEnabled={true}
                                placeholder="Регион"
                                value={selectregion}
                                onValueChanged={selectedregion}
                            />


                        </div>
                        <div className={'card-block'}>
                            <Lookup

                                labelMode='static'
                                stylingMode='outlined'
                                dataSource={'https://api.cemon.ru/listrayon/?id=' + selectregion}
                                displayExpr="label"
                                valueExpr="value"
                                searchEnabled={true}
                                placeholder="Район"
                                value={selectrayon}
                                onValueChanged={selectedrayon}
                            />


                        </div>

                        <div className={'card-block'}>


                                <DatePicker
                                    className={'dx-show-invalid-badge dx-lookup dx-lookup-popover-mode dx-textbox dx-texteditor dx-editor-outlined dx-widget dx-lookup-empty dx-texteditor-empty dx-dropdowneditor dx-dropdowneditor-field-clickable'}
                                    selected={selectedMonth}
                                    onChange={handleMonthChange}
                                    showIcon
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                    placeholderText="Выберите месяц и год"
                                    locale="ru"
                                    labelMode='static'
                                    dateFormat="MMM yyyy"
                                ></DatePicker>

                        </div>

                    </div>


                </details>


                <div id="cssportal-grid">

                    <div id="div2">
                        <div className={'title-table'}><b>Количество школ с размещенным меню</b></div>
                        <div className={'dx-card responsive-paddings'}>
                            <div className="single-chart">
                                {Kruglash(Math.round(dataobsh.СМеню / dataobsh.Всего * 100))}
                            </div>
                        </div>

                    </div>
                    <div id="div3">
                        <div className={'title-table'}><b>Доля школ(%) с нарушением в ежедневном меню</b></div>
                        <div className={'dx-card responsive-paddings'}>
                            <Chart dataSource={datagrafik} rotated={false} height={250}>

                                <Series valueField="Процент" argumentField="Дата" type="stackedspline" color="#115FA3"
                                        name="Дети">

                                    <Label visible={false} backgroundColor="#19B1BD"/>
                                </Series>
                                <Animation
                                    easing="linear"
                                    duration={500}
                                />
                                <Legend position="outside" visible={false} horizontalAlignment="center"
                                        verticalAlignment="top"/>
                                <Export enabled={true}/>

                            </Chart>

                        </div>
                    </div>

                    <div id="div4">
                        <div className={'title-table'}><b>Нарушения по видам</b></div>

                        <div className={'dx-card responsive-paddings'}>
                            <DataGrid
                                height={250}
                                dataSource={svodoshibok}
                                showBorders={true}

                                allowColumnResizing={true}
                                style={{paddingTop: 0, paddingBottom: 0}}
                                columnAutoWidth={false}
                                columnHidingEnabled={false}
                                showColumnLines={false}
                                //dataRowRender={DataRow}
                                //hoverStateEnabled={true}
                                onExporting={onExporting}
                                wordWrapEnabled={true}


                            >
                                <Scrolling mode="virtual"/>

                                <FilterRow visible={false}/>
                                <SearchPanel visible={false}/>
                                <HeaderFilter visible={false}/>
                                <Paging pageSize={5}/>
                                <Export enabled={false}/>
                                <LoadPanel
                                    height={100}
                                    width={200}
                                    indicatorSrc={require('./load.gif')}

                                />
                                <Column dataField="ПриемПищи" allowSearch={false} groupIndex={0}/>
                                <Column dataField="Текст" allowSearch={true}/>
                                <Column dataField="Количество"/>


                            </DataGrid>


                        </div>
                    </div>
                    <div id="div5">
                        <div className={'title-table'}><b>Лидеры</b></div>
                        <div className={'dx-card responsive-paddings'}>
                            <DataGrid
                                height={250}
                                dataSource={liders}
                                showBorders={true}

                                allowColumnResizing={true}
                                style={{paddingTop: 0, paddingBottom: 0}}
                                columnAutoWidth={false}
                                columnHidingEnabled={false}
                                showColumnLines={false}
                                //dataRowRender={DataRow}
                                //hoverStateEnabled={true}
                                onExporting={onExporting}
                                wordWrapEnabled={true}


                            >
                                <Scrolling mode="virtual"/>

                                <FilterRow visible={false}/>
                                <SearchPanel visible={false}/>
                                <HeaderFilter visible={false}/>
                                <Paging pageSize={5}/>
                                <Export enabled={false}/>
                                <LoadPanel
                                    height={100}
                                    width={200}
                                    indicatorSrc={require('./load.gif')}

                                />


                            </DataGrid>


                        </div>
                    </div>
                    <div id="div6">
                        <div className={'title-table'}><b>Аутсайдеры</b></div>
                        <div className={'dx-card responsive-paddings'}>
                            <DataGrid
                                height={250}
                                dataSource={outsider}
                                showBorders={true}

                                allowColumnResizing={true}
                                style={{paddingTop: 0, paddingBottom: 0}}
                                columnAutoWidth={false}
                                columnHidingEnabled={false}
                                showColumnLines={false}
                                //dataRowRender={DataRow}
                                //hoverStateEnabled={true}
                                onExporting={onExporting}
                                wordWrapEnabled={true}


                            >
                                <Scrolling mode="virtual"/>

                                <FilterRow visible={false}/>
                                <SearchPanel visible={false}/>
                                <HeaderFilter visible={false}/>
                                <Paging pageSize={5}/>
                                <Export enabled={false}/>
                                <LoadPanel
                                    height={100}
                                    width={200}
                                    indicatorSrc={require('./load.gif')}

                                />


                            </DataGrid>


                        </div>
                    </div>
                </div>

            </BrowserView>


            <MobileView>
                <details open>
                    <summary>Фильтр</summary>
                    <div>


                        <div>
                            <Lookup

                                labelMode='static'
                                stylingMode='outlined'
                                dataSource={'https://api.cemon.ru/listregion'}
                                displayExpr="label"
                                valueExpr="value"
                                searchEnabled={true}
                                placeholder="Регион"
                                value={selectregion}
                                onValueChanged={selectedregion}
                            />


                        </div>
                        <div>
                            <Lookup

                                labelMode='static'
                                stylingMode='outlined'
                                dataSource={'https://api.cemon.ru/listrayon/?id=' + selectregion}
                                displayExpr="label"
                                valueExpr="value"
                                searchEnabled={true}
                                placeholder="Район"
                                value={selectrayon}
                                onValueChanged={selectedrayon}
                            />


                        </div>

                        <div>
                            <DatePicker
                                className={'dx-show-invalid-badge dx-lookup dx-lookup-popover-mode dx-textbox dx-texteditor dx-editor-outlined dx-widget dx-lookup-empty dx-texteditor-empty dx-dropdowneditor dx-dropdowneditor-field-clickable'}
                                selected={selectedMonth}
                                onChange={handleMonthChange}
                                showIcon
                                showMonthYearPicker
                                showFullMonthYearPicker
                                placeholderText="Выберите месяц и год"
                                locale="ru"
                                labelMode='static'
                                dateFormat="MMM yyyy"
                            ></DatePicker>


                        </div>

                    </div>


                </details>

                <div id="div2">
                    <div className={'title-table'}><b>Количество школ с размещенным меню</b></div>
                    <div className={'dx-card responsive-paddings'}>
                        <div className="single-chart">
                            {Kruglash(Math.round(dataobsh.СМеню / dataobsh.Всего * 100))}
                        </div>
                    </div>

                </div>
                <div>
                    <div className={'title-table'}><b>Доля школ(%) с нарушением в ежедневном меню</b></div>
                    <div className={'dx-card responsive-paddings'}>
                        <Chart dataSource={datagrafik} rotated={false}>

                            <Series valueField="Процент" argumentField="Дата" type="stackedspline" color="#115FA3"
                                    name="Дети">

                                <Label visible={false} backgroundColor="#19B1BD"/>
                            </Series>
                            <Animation
                                easing="linear"
                                duration={500}
                            />
                            <Legend position="outside" visible={false} horizontalAlignment="center"
                                    verticalAlignment="top"/>
                            <Export enabled={true}/>

                        </Chart>

                    </div>
                </div>

                <div>
                    <div className={'title-table'}><b>Нарушения по видам</b></div>

                    <div className={'dx-card responsive-paddings'}>
                        <DataGrid
                            dataSource={svodoshibok}
                            showBorders={true}
                            allowColumnResizing={true}
                            style={{paddingTop: 0, paddingBottom: 0}}
                            columnAutoWidth={false}
                            columnHidingEnabled={false}
                            showColumnLines={false}
                            //dataRowRender={DataRow}
                            //hoverStateEnabled={true}
                            onExporting={onExporting}
                            wordWrapEnabled={true}
                        >


                            <FilterRow visible={false}/>
                            <SearchPanel visible={false}/>
                            <HeaderFilter visible={false}/>
                            <Paging pageSize={5}/>
                            <Export enabled={false}/>
                            <LoadPanel
                                height={100}
                                width={200}
                                indicatorSrc={require('./load.gif')}

                            />
                            <Column dataField="ПриемПищи" allowSearch={false} groupIndex={0}/>
                            <Column dataField="Текст" allowSearch={true}/>
                            <Column dataField="Количество"/>


                        </DataGrid>


                    </div>
                </div>

                <div>
                    <div className={'title-table'}><b>Лидеры</b></div>
                    <div className={'dx-card responsive-paddings'}>
                        <DataGrid

                            dataSource={liders}
                            showBorders={true}

                            allowColumnResizing={true}
                            style={{paddingTop: 0, paddingBottom: 0}}
                            columnAutoWidth={false}
                            columnHidingEnabled={false}
                            showColumnLines={false}
                            //dataRowRender={DataRow}
                            //hoverStateEnabled={true}
                            onExporting={onExporting}
                            wordWrapEnabled={true}


                        >


                            <FilterRow visible={false}/>
                            <SearchPanel visible={false}/>
                            <HeaderFilter visible={false}/>
                            <Paging pageSize={5}/>
                            <Export enabled={false}/>
                            <LoadPanel
                                height={100}
                                width={200}
                                indicatorSrc={require('./load.gif')}

                            />


                        </DataGrid>


                    </div>
                </div>
                <div>
                    <div className={'title-table'}><b>Аутсайдеры</b></div>
                    <div className={'dx-card responsive-paddings'}>
                        <DataGrid

                            dataSource={outsider}
                            showBorders={true}

                            allowColumnResizing={true}
                            style={{paddingTop: 0, paddingBottom: 0}}
                            columnAutoWidth={false}
                            columnHidingEnabled={false}
                            showColumnLines={false}
                            //dataRowRender={DataRow}
                            //hoverStateEnabled={true}
                            onExporting={onExporting}
                            wordWrapEnabled={true}


                        >


                            <FilterRow visible={false}/>
                            <SearchPanel visible={false}/>
                            <HeaderFilter visible={false}/>
                            <Paging pageSize={5}/>
                            <Export enabled={false}/>
                            <LoadPanel
                                height={100}
                                width={200}
                                indicatorSrc={require('./load.gif')}

                            />


                        </DataGrid>


                    </div>
                </div>

            </MobileView>

        </div>

    )
}