import React, {useState, useEffect, useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import TextBox from "devextreme-react/text-box";
import {Button} from "devextreme-react/button";
import { ProgressBar } from 'devextreme-react/progress-bar';




export default function Linkprov() {

    const [selectlink, setselectlink] = useState();
    const [result, setresult] = useState();
    const [idstatus, setidstatus] = useState(Math.floor(Math.random() * (1000000 - 1)) + 1);
    const [statload, setstatload] = useState(0);
    const [procentvipolneniya, setprocentvipolneniya] = useState('0%');
    const [status, setstatus] = useState();
    const [butenable, setbutenable] = useState(false);

    const valuelink = useCallback(({ value }) => {

        setselectlink(value);

    }, []);

    useEffect(() => {
        if(statload==1) {
            const interval = setInterval(() => {
                getprocent();
            }, 500);

            return () => clearInterval(interval);
        };
    }, [statload]);


function Porazload(){
    if(statload==1){
    return (
        <div>
        <div>
            <center><font size={5}>{procentvipolneniya}%</font><br />{status}</center>
        </div>


        </div>
    )
    }
}

    function raz(){
        setbutenable(true);
        setstatus();
        setprocentvipolneniya(0);
        setstatload(1);
        setresult('<center><img src='+require('./load.gif')+'></br>Идёт анализ данных...</br></center>');

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'param1': selectlink, 'param2':idstatus})
    };
    fetch('https://api.cemon.ru/provlink/', requestOptions)
        .then((response) => response.text())
        .then((text) => {
            setresult(text);
            setstatload(0);
            setbutenable(false);
        })
        .catch((error) => {
            setresult('Ошибка выполнения запроса');
            setbutenable(false);
            setstatload(0);
        });

    }



    function getprocent(){


        const requestOptions = {

        };
        fetch('https://api.cemon.ru/provlink/status/'+idstatus+'.json', requestOptions)
            .then((response) => response.json())
            .then((responseJson) => {
                setprocentvipolneniya( responseJson[0].Procent);
                setstatus(responseJson[0].Status);
            })
            .catch((error) => {

            });
    }

    function getLastMonth(){
        var now = new Date();
        var lastday  = new Date(now.getFullYear(), now.getMonth(), 0);
        var firstday = new Date(lastday.getFullYear(), lastday.getMonth(), 1);

        var lastMonth = '01.'+(firstday.getMonth()+1)+'.'+firstday.getFullYear();
        return lastMonth;
    }


    return (
        <div>
            <div className={'content-block'}>
                <div className={'title-table'}><b>Параметры ссылки</b></div>

                <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>
                    Начальная дата проверки данных с {getLastMonth()}
                    <TextBox
                        defaultValue=""
                        placeholder="Ссылка для проверки"
                        onValueChanged={valuelink}
                        valueChangeEvent="change"
                    /><br/>
                    <center>
                        <Button
                            text="Анализировать"
                            type="default"
                            stylingMode="contained"
                            onClick={() => raz()}
                            icon="search"
                            //registerKeyHandler={'enter',PoiskPoid()}
                            disabled={butenable}

                        />
                    </center>
                </div>
            </div>


            <div className={'content-block'}>
                <div className={'title-table'}><b>Результат</b></div>
                <div className={'dx-card responsive-paddings'} style={{fontSize: '1em'}}>

                    <div dangerouslySetInnerHTML={{__html: result}}/>

                    {Porazload()}


                </div>
            </div>


        </div>
    );
};
