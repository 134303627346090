import React, {useCallback, useState} from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/UserPanel';
import './Header.scss';
import { Template } from 'devextreme-react/core/template';
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";


export default function Header({ menuToggleEnabled, title, toggleMenu }) {




  const Refreshdata = useCallback(() => {
    window.location.reload(true);

  }, []);

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
    });
    const handleInstall = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };


  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
          
        </Item>
        <Item
          location={'before'}
          cssClass={'header-title'}
          text={title}
          visible={!!title}
        />

          <Item location={'after'} locateInMenu={'auto'}>
              <Button onClick={handleInstall}>Установить как приложение</Button>
          </Item>
          <Item location={'after'} locateInMenu={'auto'}>
              <Button width={50} type="default" stylingMode="contained" icon="refresh" onClick={() => Refreshdata()} menuMode={'context'}></Button>
          </Item>



      </Toolbar>

    </header>
)}

/*
 <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <Button
            className={'user-button authorization'}
            width={210}
            height={'100%'}
            stylingMode={'text'}
          >
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
*/