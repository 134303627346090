export const navigation = [
  {
    text: 'Главная',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Пищеблок',
    icon: 'food',
    items: [
      {
        text: 'Анализ меню',
        icon: 'datatrending',
        path: '/profile'
      },

    ]
  },
  {
  text: 'Регион/район',
  icon: 'eyeopen',
  items: [
    {
      text: 'Дашборд',
      icon: 'datausage',
      path: '/dashboard'
    },
    {
      text: 'Отчёты≡',
      icon: 'splitcells',
      path: '/upload'
    },


  ]
},
  {
    text: 'Инструменты',
    icon: 'toolbox',
    items: [
      {
        text: 'Проверка ссылки',
        path: '/linkprov'
      },
      {
        text: 'Проверка ЕМ',
        path: '/provxlsx'
      },



    ]
  },
  {
    text: 'Помощь',
    path: '/help',
    icon: 'help'
  },


  ];
