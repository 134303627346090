import React, {useCallback, useState} from "react";
import * as XLSX from 'xlsx';
import DataGrid, {Column, DataGridTypes, LoadPanel} from "devextreme-react/data-grid";
import './provxlsx.scss';



export default function Provxlsx() {
    const [data, setData] = useState([]);
    const [statusload, setstatusload] = useState([]);




    const provrazdel=(cellData: DataGridTypes.ColumnCellTemplateData) => {
        if (cellData.text.includes("!")) {
            return (<div className='examplethree'>{cellData.text.substring(0, cellData.text.length - 1)}</div>)
        };
        if  (cellData.text.includes("~")){
            return (<div className='exampleyellow'>{cellData.text.substring(0, cellData.text.length - 1)}</div>)
        };
        if  (cellData.text===''){
            return (<div className='exampleyellow'>-</div>)
        };
        return (<div>{cellData.text}</div>)

    };




    const FileUpload = () => {


        // Функция для обработки файла
        const handleFileUpload = (e) => {
            setData();
            setstatusload('Идёт обработка файла...');
            const file = e.target.files[0];
            if(e.target.files[0].size > 32768){setstatusload('Ошибка: Превышен максимальный объем файла'); return;};
            if (file) {
                const reader = new FileReader();

                // Чтение файла
                reader.onload = (event) => {
                    const binaryString = event.target.result;

                    // Используем библиотеку xlsx для парсинга
                    const workbook = XLSX.read(binaryString, {type: 'binary'});

                    // Получаем первый лист в книге (если нужно - можно работать с другими листами)
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    // Преобразуем данные в JSON (массив объектов)
                   // const jsonData = XLSX.utils.sheet_to_json(sheet, {'Прием пищи', 'Раздел', 'Блюдо'}); // header: 1 - чтобы использовать первую строку как заголовки

                    const jsonData = XLSX.utils.sheet_to_json(sheet,{range: 2,header: 1,defval: null,blankRows: true});



                    // Удаляем первые 2 строки
                    //jsonData.splice(0, 2);


                    //Первоначальная обработка массива данных
                    const headtable=["Прием пищи","Раздел","№ рец.","Блюдо","Выход, г","Цена","Калорийность","Белки","Жиры","Углеводы"];

                    for (var key in jsonData) {
                        //Если строка пустая - удалить и перейти к следующему элементу
                        //if((jsonData[key][1]==null&&jsonData[key][3]==null)){jsonData.splice(key, 1); continue;}

                        //Если заголовой изменен - шаблон испорчен
                        if (key==0){
                            for (let j = 0; j < 5; j++) {
                            if(jsonData[key][j]!==headtable[j]){

                                setstatusload('Ошибка: Шаблон изменен. Чтение невозможно. ячейка '+String.fromCharCode(j + 'A'.charCodeAt(0))+'3 не равна "'+headtable[j]+'"');return;}
                            }
                        }
                        if (key>0) {
                            //Если у строки нет приема пищи, равен предыдущей строке
                            //if((jsonData[key][0]==null)&&(key!=='1')){
                            if(Number(key)<Object.values(jsonData).length-1){
                                //console.log(Number(key)+1);
                               if(jsonData[Number(key)+1][0]==null){jsonData[Number(key)+1][0]=jsonData[key][0];}
                            }

                                //jsonData[key][0]=jsonData[key-1][0];};
                            //Если в выходе значения разделены / - сложить значения

                            if((jsonData[key][4]!==null) && (jsonData[key][4]!==undefined)){
                            if(jsonData[key][4].toString().includes("/")){
                                let massvihos=jsonData[key][4].toString().split("/");
                                let sumNum = 0
                                for (let n = 0; n < massvihos.length; n++) {sumNum += +(massvihos[n])}
                                jsonData[key][4]=sumNum;
                                                                        }
                                                        }
                                    }
                                                }


                    function summrazdel(param1, param2){
                        let i=0;
                        let summ= 0;
                        for (i in jsonData) {

                            if((jsonData[i][0]==param1)&&(jsonData[i][1]==param2)){

                                summ+=parseFloat(jsonData[i][4])};
                        }
                        console.log(summ);
                        return summ;
                    }

                    function replaceSpace(str) {
                        if (str[0] == ' ') {
                            str = '_' + str.slice(1); // Заменить первый пробел на #
                        }

                        if (str[str.length - 1] == ' ') {
                            str = str.slice(0, str.length - 1) + '_'; // Заменить последний пробел на #
                        }

                        return str;
                    }



let itogmass=[];
                    let raz="";
                    key=0;



                    for (key in jsonData) {
                        if(key==0){continue;}
                        var Priem = ['Завтрак','Завтрак 2','Обед','Полдник','Ужин','Ужин 2'];
                        var Razdl=['гор.блюдо','гор.напиток','хлеб','фрукты','закуска','1 блюдо','2 блюдо','гарнир','напиток','хлеб бел.','хлеб черн.','булочное','кисломол.','сладкое'];

                        try {
                            jsonData[key][0]=replaceSpace(jsonData[key][0]);
                            jsonData[key][1]=replaceSpace(jsonData[key][1]);

                        } catch (err) {
                        }



                       if(jsonData[key][0]==null){jsonData[key][0]="не указан"};
                        if(jsonData[key][1]==null){jsonData[key][1]="не указан"};

                        //console.log(summrazdel(jsonData[key][0],jsonData[key][1]));
                        //console.log(summrazdel(jsonData[key][0],jsonData[key][1]));

                        //проверка выхода блюд
                        if((jsonData[key][1]==='гор.блюдо')&&((summrazdel(jsonData[key][0],jsonData[key][1])<150))){jsonData[key][4]+='!';}
                        if((jsonData[key][1]==='2 блюдо')&&((summrazdel(jsonData[key][0],jsonData[key][1])<90))){jsonData[key][4]+='!';}
                        if((jsonData[key][1]==='1 блюдо')&&((summrazdel(jsonData[key][0],jsonData[key][1])<200))){jsonData[key][4]+='!';}
                        if((jsonData[key][1]==='гарнир')&&((summrazdel(jsonData[key][0],jsonData[key][1])<150))){jsonData[key][4]+='!';}
                        if((jsonData[key][1]==='закуска')&&((summrazdel(jsonData[key][0],jsonData[key][1])<60))){jsonData[key][4]+='!';}
                        if((jsonData[key][1]==='фрукты')&&((summrazdel(jsonData[key][0],jsonData[key][1])<100))){jsonData[key][4]+='!';}


                        if(!Priem.includes(jsonData[key][0])){
                            jsonData[key][0]=jsonData[key][0]+'!';
                       };
                        if(!Razdl.includes(jsonData[key][1])){
                            jsonData[key][1]=jsonData[key][1]+'~';
                        };



                        itogmass[key]={



                            'Прием пищи':jsonData[key][0],
                            'Раздел':jsonData[key][1],
                            '№ рец':jsonData[key][2],
                            'Блюдо':jsonData[key][3],
                            'Выход, г':jsonData[key][4],
                            'Цена':jsonData[key][5],
                            'Калорийность':jsonData[key][6],
                            'Белки':jsonData[key][7],
                            'Жиры':jsonData[key][8],
                            'Углеводы':jsonData[key][9],
                        };



                    }

                    //setdata(itogmass);
                   //console.log(itogmass);
                    //itogmass = JSON.stringify(itogmass);
                    for (var key in itogmass) {
                        //Если строка пустая - удалить и перейти к следующему элементу
                        if((itogmass[key]['Блюдо']==null)){
                            delete itogmass[key];
                        }
                    }

                    console.log(itogmass);
                    setData(itogmass);
                    setstatusload();

                };
                reader.readAsBinaryString(file);
            }
        };




        return (
            <center>
            <div className="dx-button-content">
                <div className="input-file-row">
                    <label className="input-file">
                        <input type="file" name="file[]" accept=".xlsx, .xls" onChange={handleFileUpload} />
                        <span>Выберите файл</span>
                    </label>
                    <div className="input-file-list"></div>
                </div>

                <div className={'dx-card responsive-paddings'}>{statusload}</div>

            </div>
            </center>
        );
    };

    return (
        <div className={'content-block'}>
            <h2>Проверка файла ежедневного меню</h2>
                    <div className={'title-table'}><b>Выбор файла и комментарии к результатам</b></div>
                    <div className={'dx-card responsive-paddings'}>


                        <FileUpload/>
                    </div>
                    <div className={'title-table'}><b>Результат проверки</b></div>
                    <div className={'dx-card responsive-paddings'}>
                        <DataGrid className={'dx-datagridd'}
                                  dataSource={data}
                                  showBorders={true}
                                  allowColumnResizing={true}
                                  columnAutoWidth={true}
                                  columnHidingEnabled={false}
                                  showColumnLines={true}
                            //dataRowRender={DataRow}
                            //hoverStateEnabled={true}
                            //onExporting={onExporting}
                                  wordWrapEnabled={true}>

                            <Column dataField="Прием пищи" cellRender={provrazdel}/>
                            <Column dataField="Раздел" dataType="text" cellRender={provrazdel}/>
                            <Column dataField="№ рец" cellRender={provrazdel}/>
                            <Column dataField="Блюдо" cellRender={provrazdel}/>
                            <Column dataField="Выход, г" cellRender={provrazdel}/>
                            <Column dataField="Цена" cellRender={provrazdel}/>
                            <Column dataField="Калорийность" cellRender={provrazdel}/>
                            <Column dataField="Белки" cellRender={provrazdel}/>
                            <Column dataField="Жиры" cellRender={provrazdel}/>
                            <Column dataField="Углеводы" cellRender={provrazdel}/>


                            <LoadPanel
                                height={100}
                                width={200}
                                indicatorSrc={require('./load.gif')}

                            />
                        </DataGrid>
                    </div>
        </div>

    )

}