import React, {useState, useEffect, useCallback} from 'react';
import { useNavigate } from "react-router-dom";





export default function Pwa() {
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        setDeferredPrompt(e);
    });
    const handleInstall = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
            });
        }
    };
    return (
        <div>
            <button onClick={handleInstall}>Install App</button>
        </div>
    );
};
